<template>
    <b-form class="border-bottom mb-4">
        <b-form-row>
            <h2 class="text-primary-light text-uppercase">{{ title }}</h2>
        </b-form-row>

        <b-form-row>
            <b-col sm="6">
                <b-form-group
                    v-if="showDestination"
                    :label="t('checkout_Destination')"
                    label-for="select-destination"
                >
                    <b-form-select
                        id="select-destination"
                        ref="destination"
                        :options="addressOptions"
                        :value="value.customerAddressId"
                        @input="setCustomerAddress"
                        required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    v-if="showResponsibleParty"
                    :label="t('checkout_ResponsibleParty')"
                    label-for="input-responsible-party"
                >
                    <b-form-input
                        id="input-responsible-party"
                        :disabled="true"
                        :value="value.responsibleParty"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group
                    v-if="showDestination"
                    :label="t('checkout_ShippingMethod')"
                    label-for="select-shipping-method"
                >
                    <b-form-select
                        id="select-shipping-method"
                        :options="shippingMethodOptions"
                        v-model="$v.value.shippingMethodId.$model"
                        :state="setState(false, $v.value.shippingMethodId)"
                        required
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col sm="6">
                <b-form-group :label="t('checkout_StreetAddress')" label-for="input-street-address">
                    <b-form-input
                        id="input-street-address"
                        :disabled="disabledStatus"
                        v-model="streetAddress"
                        :state="setState(disabledStatus, $v.streetAddress)"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group
                    :label="t('checkout_Phone')"
                    label-for="input-phone"
                    :invalid-feedback="vErrorMessage($v.value.phoneNumber)"
                >
                    <b-form-input
                        id="input-phone"
                        :disabled="disabledStatus"
                        v-model="$v.value.phoneNumber.$model"
                        :state="setState(disabledStatus, $v.value.phoneNumber)"
                        required
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col sm="3">
                <b-form-group :label="t('checkout_City')" label-for="input-city">
                    <b-form-input
                        id="input-city"
                        :disabled="disabledStatus"
                        v-model="value.city"
                        :state="setState(disabledStatus, $v.value.city)"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col v-if="this.currentZone === ZoneCodes.US" sm="3">
                <b-form-group :label="t('checkout_State')" label-for="input-state">
                    <b-form-input
                        id="input-state"
                        :disabled="disabledStatus"
                        v-model="value.state"
                        :state="setState(disabledStatus, $v.value.state)"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group :label="t('checkout_Zip')" label-for="input-zip">
                    <b-form-input
                        id="input-zip"
                        :disabled="disabledStatus"
                        v-model="value.zip"
                        :state="setState(disabledStatus, $v.value.zip)"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="t('checkout_Country')" label-for="input-country">
                    <b-form-input
                        id="input-country"
                        :disabled="disabledStatus"
                        v-model="value.country"
                        :state="setState(disabledStatus, $v.value.country)"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
                <b-form-group>
                    <template #label>
                        <div class="d-flex justify-content-between">
                            <span class="label"> {{ noteLabel }} </span>
                            <span class="text-caption text-gray-darker">
                                {{ noteLength }}/100
                            </span>
                        </div>
                    </template>
                    <b-form-textarea
                        id="note"
                        v-model="value[noteProperty]"
                        rows="4"
                        maxlength="100"
                        no-resize
                    >
                    </b-form-textarea>
                </b-form-group>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {ZoneCodes} from '@/constants/zone';
import {allowedCharactersPhoneUS, vErrorMessage} from '@/utilities/validators';
import {AddressTypes} from '../../../constants/customer';

export default {
    name: 'AddressDetails',
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: null,
        },
        showDestination: {
            type: Boolean,
            default: false,
        },
        showResponsibleParty: {
            type: Boolean,
            default: false,
        },
        noteLabel: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ZoneCodes,
            submitted: false,
        };
    },
    validations: {
        value: {
            shippingMethodId: {
                required,
            },
            phoneNumber: {
                required,
                allowedCharactersPhoneUS,
            },
            city: {
                required,
            },
            state: {
                required,
            },
            zip: {
                required,
            },
            country: {
                required,
            },
        },
        streetAddress: {
            required,
        },
    },
    computed: {
        ...mapGetters('customers', ['shippingAddresses']),
        ...mapGetters('cart', ['shippingMethods']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters('user', ['currentUser']),
        streetAddress: {
            get() {
                try {
                    const addressArray = JSON.parse(this.value.address);
                    return addressArray.join(', ');
                } catch (e) {
                    return this.value.address;
                }
            },
            set(value) {
                this.value.address = value;
            },
        },
        addressOptions() {
            return this.shippingAddresses.map((a) => {
                return {
                    value: a.customerAddressId,
                    text: a.name,
                };
            });
        },
        shippingMethodOptions() {
            let shippingMethodOptions = this.shippingMethods.map((s) => {
                return {
                    value: s.shippingMethodId,
                    text: s.name,
                };
            });
            shippingMethodOptions.unshift({
                value: null,
                text: this.t('checkout_SelectShippingMethod'),
            });
            return shippingMethodOptions;
        },
        disabledStatus() {
            return !this.isTempShippingAddress(this.value);
        },
        noteLength() {
            const note = this.value[this.noteProperty];
            return note ? note.length : 0;
        },
        noteProperty() {
            return this.showDestination ? 'deliveryInstructions' : 'billingInstructions';
        },
    },
    methods: {
        vErrorMessage,
        ...mapActions({
            fetchAddresses: 'customers/fetchAddresses',
            fetchShippingMethods: 'cart/fetchShippingMethods',
        }),
        async getActiveCustomerAddresses() {
            let includeTemp = this.currentZone === ZoneCodes.US;
            await this.fetchAddresses(includeTemp);

            if (!this.value.customerAddressId && this.addressOptions.length) {
                this.setCustomerAddress(this.addressOptions[0].value);
            }
        },
        /**
         * Determines if the given address is the temporary shipping address
         *
         * @param {Object} address the address to check
         */
        isTempShippingAddress(address) {
            return (
                address?.allowAddressEntry && address?.addressType === AddressTypes.TEMP_SHIPPING
            );
        },
        /**
         * Determines if the form is valid
         */
        isValid() {
            this.$v.$touch();
            if (this.isTempShippingAddress(this.value)) {
                return !this.$v.$invalid;
            } else {
                return !this.$v.value.shippingMethodId.$invalid;
            }
        },
        /**
         * Sets the selected customer address based on the given id
         *
         * @param {Number} customerAddressId id for the customer address
         */
        setCustomerAddress(customerAddressId) {
            let address = this.shippingAddresses.find(
                (a) => a.customerAddressId === customerAddressId
            );

            if (address) {
                address.shippingMethodId = this.value.shippingMethodId;
                address.deliveryInstructions = this.value.deliveryInstructions;
                this.$emit('input', address);
            }
        },
        /**
         * Sets the shipping method ID to a default null value if the
         * model's shipping method ID cannot be found in the available
         * shipping methods.
         */
        setDefaultShippingMethodId() {
            let shippingMethod = this.shippingMethods.find(
                (s) => s.shippingMethodId === this.value.shippingMethodId
            );

            if (!shippingMethod) {
                this.value.shippingMethodId = null;
            }
        },
        /**
         * Sets the validation state of a form element
         *
         * If the form is disabled or the element hasn't been touched yet then
         * do not display any validation indication. Otherwise, display the
         * validation result.
         * @param {Boolean} disabledStatus the disabled status of the element
         * @param {Object} validationResult the validation result of the element
         */
        setState(disabledStatus, validationResult) {
            return disabledStatus || !validationResult.$dirty ? null : !validationResult.$invalid;
        },
    },
    async mounted() {
        if (this.showDestination) {
            await this.getActiveCustomerAddresses();
            await this.fetchShippingMethods();
            this.setDefaultShippingMethodId();
        }
    },
};
</script>
<style lang="scss" scoped></style>
